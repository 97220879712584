import { IMobileMenu } from '~/interfaces/menus/mobile-menu';

const dataMobileMenu: IMobileMenu = [
  {
    type: 'link',
    title: 'Home',
    url: 'https://dhome.solutions/',
  },
  {
    type: 'link',
    title: 'Shop',
    url: '/shop/catalog',
  },
  {
    type: 'link',
    title: 'Price List',
    url: 'https://admin.dhome.solutions/api/static/general/SpecialBonusPackages.pdf',
  },
  {
    type: 'link',
    title: 'Contact Us',
    url: 'https://dhome.solutions/page-contact',
  },
];

export default dataMobileMenu;
