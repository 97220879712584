import Vue from 'vue';
import { Plugin } from '@nuxt/types';

function datetime(this: Vue, unix: number): string {
  if (!unix) {
    return '';
  }

  return new Date(unix * 1000)
    .toLocaleDateString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .toUpperCase()
    .replace(',', '');
}

declare module 'vue/types/vue' {
  interface Vue {
    $datetime: typeof datetime;
  }
}

const plugin: Plugin = (_context, inject) => {
  inject('datetime', datetime);
};

export default plugin;
