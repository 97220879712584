import VueI18n from 'vue-i18n'
import { Context, Plugin } from '@nuxt/types'
import { IProduct } from '~/interfaces/product'
import { IUserAddress } from '~/interfaces/address'
import { IOrder } from '~/interfaces/order'
import { ICategory, IShopCategory } from '~/interfaces/category'

function make(context: Context) {
    return {
        home() {
            return '/'
        },
        login() {
            return '/account/login'
        },
        category(category: ICategory) {
            if (category.type === 'categories') {
                return context.$url.shopCategory(category)
            }

            return ''
        },
        blogCategory() {
            return ''
        },
        shopCategory(category: IShopCategory) {
            return `/shop/catalog/${category.id}`
        },
        catalog() {
            return '/shop/catalog'
        },
        catalogues() {
            return '/catalogues'
        },
        product(product: any) {
            const id = product ? product.id || product.ID : 0
            return `/shop/products/${id}`
        },
        cart() {
            return '/shop/cart'
        },
        checkout() {
            return '/checkout'
        },
        wishlist() {
            return '/shop/wishlist'
        },
        trackOrder() {
            return '/shop/track-order'
        },
        signIn() {
            return '/account'
        },
        signUp() {
            return '/site/create-account'
        },
        forgot() {
            return '/site/forgot'
        },
        signOut(this: any) {
            return '/account/logout'
        },
        myOrders() {
            return `${process.env.URL}/dashboard/myorders/`
        },
        myFeedbacks() {
            return `${process.env.URL}/dashboard/myfeedbacks/`
        },
        myIncidents() {
            return `${process.env.URL}/dashboard/myincidents/`
        },
        account() {
            return '/account'
        },
        accountDashboard() {
            // return `${process.env.URL}`
            return '/account/dashboard'
        },
        accountProfile() {
            // return `${process.env.URL}/dashboard/account/update-profile/`
            return '/account/profile'
        },
        accountUserlist() {
            return '/account/userlist'
        },
        accountSalesHistory() {
            return '/account/sales'
        },
        accountJobs() {
            return '/account/jobs'
        },
        accountCustomers() {
            return '/account/customers'
        },
        accountEmployees() {
            return '/account/employees'
        },
        accountPosting() {
            return '/account/postings'
        },
        accountFeedback() {
            return '/account/feedback'
        },
        accountOrders() {
            return '/account/orders'
        },
        accountOrder(order: Pick<IOrder, 'id'>) {
            return `/account/orders/${order.id}`
        },
        accountAddresses() {
            return '/account/addresses'
        },
        accountAddress(address: Pick<IUserAddress, 'id'>) {
            return `/account/addresses/${address.id}`
        },
        accountPassword() {
            return '/account/password'
        },
        lang(path: string) {
            const locale = context.store.state.locale.current

            if (path[ 0 ] !== '/') {
                path = `/${path}`
            }

            if (!context.app.i18n) {
                return path
            }

            const i18n = context.app.i18n as VueI18n.I18nOptions

            if (locale === i18n.fallbackLocale) {
                return path
            }

            return `/${locale}${path}`
        },
        isExternal(path: string): boolean {
            return /^(https?:)?\/\//.test(path)
        },
        anyLink(path: string) {
            return context.$url.isExternal(path) ? path : this.base(context.$url.lang(path))
        },
        blog() {
            return '/blog/category-classic'
        },
        blogPost() {
            return '/blog/post-classic'
        },
        blogPostView(content: any) {
            return `/blog/post/${content.id}`
        },
        about() {
            return '/site/about-us'
        },
        contacts() {
            return '/site/contact-us'
        },
        terms() {
            return '/site/terms'
        },
        base(url: string) {
            if (url && url[ 0 ] === '/') {
                return context.base + url.substr(1)
            }

            return url
        },
        img(url: any) {
            return this.base(url)
        },
        image(path: string, id: number, name: string) {
            return `${process.env.BaseURLStatic}/${path}/${id}/${name}`
        },
        catalogDownload(path: string, id: number, name: string) {
            return `${process.env.BaseURLStatic}/${path}/${id}/${name}`
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $url: ReturnType<typeof make> & Context
    }
}

declare module '@nuxt/types' {
    interface Context {
        $url: ReturnType<typeof make> & Context
    }
}

const plugin: Plugin = (context, inject) => {
    inject('url', make(context))
}

export default plugin
