
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { IMobileMenuLink } from '~/interfaces/menus/mobile-menu';
  import AppLink from '~/components/shared/app-link.vue';
  import Collapse from '~/components/shared/collapse.vue';
  import ArrowRoundedDown12x7Svg from '~/svg/arrow-rounded-down-12x7.svg';

  @Component({
    name: 'MobileLinks',
    components: { AppLink, Collapse, ArrowRoundedDown12x7Svg },
  })
  export default class MobileLinks extends Vue {
    @Prop({ type: Number, default: () => 0 }) readonly level!: number;
    @Prop({ type: Array, default: () => [] })
    readonly links!: IMobileMenuLink[];

    onItemClick(item: IMobileMenuLink) {
      this.$emit('itemClick', item);
    }
  }
