
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { State } from 'vuex-class';
  import { RootState } from '~/store';
  import { IMobileMenu, IMobileMenuLink } from '~/interfaces/menus/mobile-menu';
  import MobileLinks from '~/components/mobile/mobile-links.vue';
  import dataMobileMenu from '~/data/mobileMenu';
  import Cross20Svg from '~/svg/cross-20.svg';

  @Component({
    components: { MobileLinks, Cross20Svg },
  })
  export default class MobileMenu extends Vue {
    @State((state: RootState) => state.mobileMenu.isOpen) isOpen!: boolean;

    bodyWidth: number = 0;

    links: IMobileMenu = dataMobileMenu;

    @Watch('isOpen')
    onIsOpenChange(newValue: boolean) {
      if (newValue) {
        this.open();
      } else {
        this.close();
      }
    }

    open() {
      this.hideScrollbar();
    }

    close() {
      this.showScrollbar();
    }

    hideScrollbar() {
      this.bodyWidth = document.body.clientWidth;

      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${
        document.body.clientWidth - this.bodyWidth
      }px`;
    }

    showScrollbar() {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }

    onItemClick(item: IMobileMenuLink) {
      this.$store.commit('mobileMenu/close');
    }
  }
